import { QPagination as _component_q_pagination } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container flex-vgap" }
const _hoisted_2 = { class: "grid" }

import { computed } from 'vue'
import { APP_DESC, APP_TITLE, NUM_MEMES_PER_PAGE, S3_SIZE_CARD } from '@/common/Constants'
import { getS3PublicUrlMemeTemplateFile } from '@/common/utils/getS3'
import { useMemeTemplateStore } from '@/web/client/store/MemeTemplate/useMemeTemplateStore'
import { downloadRaws } from '@/web/client/utils/download'
import { getFirstMemeTemplateFileUrl } from '@/web/client/utils/getFirstMemeTemplateFileUrl'
import { getImageAsset } from '@/web/client/utils/getImageAsset'
import { useSeoMeta } from '@unhead/vue'
import { usePagination } from '@/web/client/utils/usePagination'
import { MemeTemplateResponse } from '@/web/server/interfaces/Responses/MemeTemplateResponse'
import GridCard from './GridCard.vue'
import { RouteName } from '@/web/client/router/routes'


export default /*@__PURE__*/_defineComponent({
  __name: 'GridMemeTemplate',
  setup(__props) {

const memeTemplateStore = useMemeTemplateStore()
const { currentPage, onPaginationChange, maxPages, updateMaxPage } = usePagination(NUM_MEMES_PER_PAGE)
updateMaxPage(memeTemplateStore.approvedTemplates.length)

const pageMemeTemplates = computed<Array<MemeTemplateResponse>>(() => {
    const offset = (currentPage.value - 1) * NUM_MEMES_PER_PAGE
    return memeTemplateStore.approvedTemplates.slice(offset, offset + NUM_MEMES_PER_PAGE)
})

const downloadTemplate = (memeTemplate: MemeTemplateResponse) => {
    const fileNames = memeTemplate.files.map((file) => getS3PublicUrlMemeTemplateFile(file.fileName))
    downloadRaws(fileNames)
}

const title = computed(() => currentPage.value > 1 ? `${APP_TITLE} | Page ${currentPage.value}` : APP_TITLE)
const ogImage = getImageAsset('yametekudastop.png')
useSeoMeta({
    title,
    description: APP_DESC,
    ogImage,
    twitterCard: 'summary_large_image',
})

return (_ctx: any,_cache: any) => {
  

  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pageMemeTemplates.value, (memeTemplate) => {
        return (_openBlock(), _createBlock(GridCard, {
          key: memeTemplate.slug,
          label: memeTemplate.name,
          tooltip: `Create ${memeTemplate.name} Meme`,
          route: {
                    name: _unref(RouteName).MemeEditor,
                    params: {
                        slug: memeTemplate.slug,
                    },
                },
          image: _unref(getFirstMemeTemplateFileUrl)(memeTemplate, _unref(S3_SIZE_CARD)),
          "background-color": memeTemplate.defaultBgColor ?? undefined,
          onDownload: ($event: any) => (downloadTemplate(memeTemplate))
        }, null, 8, ["label", "tooltip", "route", "image", "background-color", "onDownload"]))
      }), 128))
    ]),
    (_unref(maxPages) > 0)
      ? (_openBlock(), _createBlock(_component_q_pagination, {
          key: 0,
          modelValue: _unref(currentPage),
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (_isRef(currentPage) ? (currentPage).value = $event : null)),
            _unref(onPaginationChange)
          ],
          max: _unref(maxPages),
          "max-pages": 5,
          "direction-links": "",
          "boundary-links": ""
        }, null, 8, ["modelValue", "max", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ]))
}
}

})